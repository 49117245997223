const BACKEND_DOMAIN = (() => {
  if (window.location.hostname.indexOf('xbot.com.vn') !== -1) return 'xbot.com.vn';
  return 'back3nd.xyz';
})();

export const BACKEND = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return 'http://localhost:5005';
    case 'qa':
      return 'https://qaconsole.back3nd.xyz';
    default:
      return `https://consoles.${BACKEND_DOMAIN}`;
  }
})();

export const AIO = (() => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
    case 'qa':
      return 'https://qaaio.back3nd.xyz';
    default:
      return `https://aios.${BACKEND_DOMAIN}`;
  }
})();

export const NOTIFICATIONS = `${AIO}/api/notifications`;

export const RESOURCES = `https://resources.${BACKEND_DOMAIN}`;
