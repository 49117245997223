import { Button, Result } from 'antd';
import { translations } from 'locales/translations';
import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';
import type { WithTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';

type Props = WithTranslation & {
  children: ReactNode;
};
type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  public render() {
    const { children, t } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Result
          status="500"
          title="500"
          subTitle={t(translations.errors.errorBoundaryPage)}
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              {t(translations.titles.home)}
            </Button>
          }
        />
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
