import { Button, Layout, Space, Typography } from 'antd';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { BiSupport } from 'react-icons/bi';
import { VscRemoteExplorer } from 'react-icons/vsc';
import { useTheme } from 'react-jss';

import packageJson from '../../../../../package.json';
import useStyles from './styles';

const Footer = () => {
  const theme = useTheme<ResponsiveTheme>();
  const S = useStyles();
  const { t } = useTranslation();

  return (
    <Layout.Footer className={S.container}>
      <Typography.Text>
        {theme.isTinyScreen
          ? `CONSOLE v${packageJson.version}`
          : `CONSOLE v${packageJson.version} • All Rights Reserved.`}
      </Typography.Text>
      <Space>
        <Button
          type="text"
          size="small"
          icon={<VscRemoteExplorer size={12} />}
          onClick={() =>
            window.open(
              'https://ultraviewer.net/vi/',
              t(translations.actions.teamview) ?? 'teamview',
              'location:no'
            )
          }
        >
          {t(translations.actions.teamview)}
        </Button>
        {window.fcWidget ? (
          <Button
            type="text"
            size="small"
            icon={<BiSupport size={12} />}
            onClick={() => window.fcWidget?.open()}
          >
            {t(translations.actions.support)}
          </Button>
        ) : null}
      </Space>
    </Layout.Footer>
  );
};

export default Footer;
