import { createUseStyles } from 'react-jss';

export default createUseStyles<'container' | 'logo' | 'menu'>({
  container: () => ({
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
    height: 48,
    paddingInline: 5,
    justifyContent: 'space-between',
  }),
  logo: {
    verticalAlign: 'middle !important',
  },
  menu: {
    backgroundColor: 'transparent',
    '& .ant-select-selector': {
      minWidth: 200,
    },
  },
});
