import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { createAjvValidator, JSONEditor, Mode } from 'vanilla-jsoneditor';

type Props = {
  initValue: any;
  onChange: (value: any) => void;
  schema?: any;
  readOnly?: boolean;
};

const Editor = ({ initValue, onChange, schema, readOnly }: Props) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const refEditor = useRef<JSONEditor | null>(null);
  const refValue = useRef<CustomObject<any>>(initValue);
  useEffect(() => {
    if (refContainer.current) {
      refEditor.current = new JSONEditor({
        target: refContainer.current,
        props: {
          content: {
            json: initValue,
          },
          mode: Mode.text,
          mainMenuBar: false,
          readOnly,
          onChange: (content: any, prev: any, status: any) => {
            if (!_.get(status, 'contentErrors.validationErrors', []).length) {
              try {
                const parsed = JSON.parse(_.get(content, 'text', '{}'));
                refValue.current = parsed;
              } catch {}
            }
          },
          onBlur: () => {
            onChange(refValue.current);
          },
        },
      });

      if (schema) {
        refEditor.current.updateProps({
          validator: createAjvValidator({ schema }),
        });
      }
    }

    return () => {
      if (refEditor.current) {
        refEditor.current.destroy();
        refEditor.current = null;
      }
    };
  }, [initValue, onChange, readOnly, schema]);

  return <div ref={refContainer} />;
};

export default Editor;
