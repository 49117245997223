import { AIO, BACKEND } from 'configs';

import Api from './api';

export const backendService = new Api({
  baseURL: BACKEND,
  withCredentials: true,
});

export const aioService = new Api({
  baseURL: AIO,
});
