import type { CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice as createSliceOriginal } from '@reduxjs/toolkit';

import type { RootStateKeyType } from '../types/injector-typings';

/* Wrap createSlice with stricter Name options */
export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends RootStateKeyType
>(
  options: CreateSliceOptions<State, CaseReducers, Name>
) => createSliceOriginal(options);
