import type { MediaQueryAllQueryable } from 'react-responsive';

export const BIG_SCREEN: Partial<MediaQueryAllQueryable> = {
  minWidth: '1920px',
};
export const NORMAL_SCREEN: Partial<MediaQueryAllQueryable> = {
  minWidth: '1024px',
};
export const SMALL_SCREEN: Partial<MediaQueryAllQueryable> = {
  maxWidth: '800px',
};
export const TINY_SCREEN: Partial<MediaQueryAllQueryable> = {
  maxWidth: '550px',
};
