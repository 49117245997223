import { createUseStyles } from 'react-jss';

export default createUseStyles<'cell' | 'row' | 'input'>({
  cell: {
    minHeight: 30,
    cursor: 'pointer',
    padding: '4px 3px',
  },
  row: {
    '& td': {
      padding: 10,
    },
    '&:hover .cell': {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset',
      borderRadius: 2,
      padding: '4px 3px',
    },
  },
  input: {
    margin: 0,
  },
});
