import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

type Props = {
  value?: number;
  onChange?: (value?: number) => void;
  style?: React.CSSProperties;
  format?: string;
  placeholder?: string;
  allowClear?: boolean;
};

const CustomDatePicker = ({ value, onChange, style, format, placeholder, allowClear }: Props) => (
  <MyDatePicker
    value={value ? moment(value) : null}
    onChange={() => {
      if (onChange) {
        onChange(value);
      }
    }}
    style={style}
    format={format || 'DD/MM/YYYY'}
    placeholder={placeholder}
    allowClear={allowClear}
  />
);

export default CustomDatePicker;
