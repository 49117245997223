import _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

moment.updateLocale('vi', {
  months: _.range(12).map((i) => `Tháng ${i + 1}`),
  monthsShort: _.range(12).map((i) => `Tháng ${i + 1}`),
  monthsParseExact: true,
  weekdays: ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'],
  weekdaysShort: ['CN', 'HAI', 'BA', 'TƯ', 'NĂM', 'SÁU', 'BẢY'],
  weekdaysMin: ['CN', 'HAI', 'BA', 'TƯ', 'NĂM', 'SÁU', 'BẢY'],
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Hôm nay lúc] LT',
    nextDay: '[Ngày mai lúc] LT',
    nextWeek: 'dddd [tuần tới] LT',
    lastDay: '[Hôm qua lúc] LT',
    lastWeek: 'dddd [tuần trước] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s',
    past: '%s trước',
    s: 'vài giây',
    m: 'một phút',
    mm: '%d phút',
    h: '1 tiếng',
    hh: '%d tiếng',
    d: 'một ngày',
    dd: '%d ngày',
    M: 'một tháng',
    MM: '%d tháng',
    y: 'một năm',
    yy: '%d năm',
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

moment.defaultFormat = 'D/M/Y';

export const calculateMonthsAge = (
  birthDay: number,
  date: number,
  ageRule: string = 'month'
): number => {
  switch (ageRule) {
    case 'month':
      return moment(date).startOf('months').diff(moment(birthDay).startOf('months'), 'months');
    case 'date':
      return Math.trunc(moment(date).diff(moment(birthDay), 'months', true));
    default:
      return -1;
  }
};

export const date2ObjectId = (date: number) =>
  `${Math.floor(date / 1000).toString(16)}0000000000000000`;

export const objectId2Date = (objectId: string): number =>
  parseInt(objectId.substring(0, 8), 16) * 1000;

export const daysFromNow = (date: number) => moment(date).diff(moment().startOf('days'), 'days');

export const getSchoolYear = (config: CustomObject<number>, date: Moment) => {
  const month = date.month() + 1;
  const year = date.year();
  const startMonth = config[year] ?? 8;

  return month >= startMonth ? year : year - 1;
};

export const getWeekDay = (date: number) =>
  ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'][moment(date).weekday()];

export const enumerateDaysBetweenDates = (startDate: Moment, endDate: Moment) => {
  const dates = [];

  const currDate = startDate.startOf('day');
  const lastDate = endDate.startOf('day');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate().getTime());
    currDate.add(1, 'days');
  }

  return dates;
};

export const getNumberSatAndSunInMonth = (month: number, year: number) => {
  const firstDate = moment()
    .year(year)
    .month(month - 1)
    .date(1);
  const lastDate = firstDate.clone().endOf('month');
  let saturdayCount = 0;
  let sundayCount = 0;

  for (let date = firstDate.clone(); date.isBefore(lastDate); date.add(1, 'day')) {
    if (date.day() === 6) saturdayCount += 1;
    if (date.day() === 0) sundayCount += 1;
  }
  return { saturdayCount, sundayCount };
};

/**
 * Get number of day of week in month
 * @param month: number
 * @param year: number
 * @param days: number[] - Array of day of week (0: Sunday -> 6: Saturday)
 * @returns `CustomObject<number>: { [dayOfWeek: number]: number }`
 */
export const getNumberDayOfWeekInMonth = (month: number, year: number, days: number[]) => {
  const firstDate = moment()
    .year(year)
    .month(month - 1)
    .date(1);
  const lastDate = firstDate.clone().endOf('month');
  const result: CustomObject<number> = {};

  for (let date = firstDate.clone(); date.isBefore(lastDate); date.add(1, 'day')) {
    const day = date.day();
    if (days.includes(day)) {
      if (!result[day]) {
        _.setWith(result, day, 0, Object);
      }
      result[day] += 1;
    }
  }
  return result;
};
