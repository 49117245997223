import { Layout, Menu } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { translations } from 'locales/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineApi } from 'react-icons/ai';
import { MdDataUsage, MdOutlinePayment, MdShare } from 'react-icons/md';
import { TbPackageImport } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router-dom';

import useStyles from './styles';

const Sider = () => {
  const S = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const activeMenu = useMemo(() => _.compact(location.pathname.split('/')), [location.pathname]);

  const menuItems = useMemo<ItemType[]>(() => {
    const items: ItemType[] = [
      {
        key: 'apis',
        label: t(translations.titles.api),
        icon: <AiOutlineApi />,
        children: [
          {
            key: 'inbound',
            label: t(translations.titles.inbound),
            icon: <TbPackageImport />,
            onClick: () => navigate('/apis/inbound'),
          },
          {
            key: 'outbound',
            label: t(translations.titles.outbound),
            icon: <MdShare />,
            onClick: () => navigate('/apis/outbound'),
          },
        ],
      },
      {
        key: 'billing',
        label: t(translations.titles.billing),
        icon: <MdOutlinePayment />,
        onClick: () => navigate('/billing'),
      },
      {
        key: 'usage',
        label: t(translations.titles.usage),
        icon: <MdDataUsage />,
        onClick: () => navigate('/usage'),
      },
    ];
    return items;
  }, [navigate, t]);

  return (
    <Layout.Sider className={S.container} breakpoint="xl" width={210}>
      <Menu selectable={false} selectedKeys={activeMenu} items={menuItems} mode="inline" />
    </Layout.Sider>
  );
};

export default Sider;
