import { createUseStyles } from 'react-jss';

export default createUseStyles<'container' | 'logo'>({
  container: {
    background: 'none !important',
    overflow: 'auto',
    paddingTop: 50,
    '& ul': {
      background: 'transparent',
      borderInlineEnd: 'none !important',
    },
    '& .ant-layout-sider-trigger': {
      background: 'transparent',
    },
  },
  logo: {
    verticalAlign: 'middle !important',
  },
});
