import { Avatar, Button, Layout, Menu, Select, Space, Typography } from 'antd';
import { useUserSlice } from 'features/user/store';
import { selectUserInformation } from 'features/user/store/selectors';
import { translations } from 'locales/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdLogout } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';

const Header = () => {
  const S = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInformation = useSelector(selectUserInformation);
  const { actions } = useUserSlice();

  const unit = useMemo(() => {
    if (userInformation?.units) {
      return _.find(userInformation.units, ['id', userInformation.activeUnit]);
    }
    return null;
  }, [userInformation]);

  const options = useMemo(
    () => userInformation?.units?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
    [userInformation?.units]
  );

  return (
    <Layout.Header className={S.container}>
      <Space>
        <Space key="title">
          <Avatar className={S.logo} src="/icon-192x192.png" />
          <Typography.Text>{t(translations.titles.console)}</Typography.Text>
          {unit ? (
            <Menu
              className={S.menu}
              selectable={false}
              items={[
                {
                  key: 'unit',
                  title: unit.name,
                  label:
                    (userInformation?.units ?? []).length > 1 ? (
                      <Select
                        options={options}
                        value={unit?.id}
                        onChange={(e) => dispatch(actions.selectUnit(e))}
                      />
                    ) : (
                      unit.name
                    ),
                },
              ]}
            />
          ) : null}
        </Space>
      </Space>
      <Space key="avatar">
        <Typography.Text>{userInformation?.displayName ?? userInformation?.email}</Typography.Text>
        <Button type="text" icon={<MdLogout />} onClick={() => dispatch(actions.signOut())} />
      </Space>
    </Layout.Header>
  );
};

export default Header;
