import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout } from 'antd';
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { translations } from 'locales/translations';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useStyles from './styles';

const Paths = () => {
  const S = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const items = useMemo<ItemType[]>(() => {
    const paths = location.pathname.split('/');
    return _.uniqBy(
      _.compact(
        paths.map((page) => {
          switch (page) {
            case '':
              return {
                key: '',
                title: (
                  <>
                    <HomeOutlined />
                    <span>{t(translations.titles.home)}</span>
                  </>
                ),
                onClick: () => navigate(''),
              };
            case 'apis':
              return {
                key: 'apis',
                title: t(translations.titles.api),
                menu: {
                  items: [
                    {
                      key: 'inbound',
                      label: t(translations.titles.inbound),
                      onClick: () => navigate('/apis/inbound'),
                    },
                    {
                      key: 'outbound',
                      label: t(translations.titles.outbound),
                      onClick: () => navigate('/apis/outbound'),
                    },
                  ],
                },
              };
            case 'inbound':
              return {
                key: 'inbound',
                title: t(translations.titles.inbound),
              };
            case 'outbound':
              return {
                key: 'outbound',
                title: t(translations.titles.outbound),
              };
            case 'billing':
              return {
                key: 'billing',
                title: t(translations.titles.billing),
              };
            case 'usage':
              return {
                key: 'usage',
                title: t(translations.titles.usage),
              };
            default:
              return {
                key: page,
                title: page,
              };
          }
        })
      ),
      (e) => e.key
    );
  }, [location, t, navigate]);

  if (items.length) {
    return (
      <Layout.Header className={S.container}>
        <Breadcrumb items={items} />
      </Layout.Header>
    );
  }
  return null;
};

export default Paths;
