import { Calendar } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/es/generate/moment';

import Header from './Header';

type Props = {
  value: number;
  onChange?: (value: number) => void;
};

const CustomCalendar = Calendar.generateCalendar<Moment>(momentGenerateConfig);

const MonthCalendar = ({ value, onChange }: Props) => (
  <CustomCalendar
    fullscreen={false}
    headerRender={Header}
    value={moment(value)}
    onChange={(selected) => {
      if (onChange && selected.diff(value, 'day')) {
        onChange(selected.startOf('day').valueOf());
      }
    }}
  />
);

export default MonthCalendar;
