import { notification } from 'antd';
import type { AuthResult, SignInPayload } from 'features/user/types';
import { getAuth, signOut as signOutFirebase } from 'firebase/auth';
import { t } from 'i18next';
import { translations } from 'locales/translations';
import _ from 'lodash';
import { all, put, takeLatest } from 'redux-saga/effects';
import { backendService } from 'services';
import formatError from 'utils/formatError';

import { actions as userActions } from './reducer';

function* signedIn(action: SignInPayload) {
  if (action.payload) {
    const { uid, email, displayName, photoURL } = action.payload;

    const token: string = yield getAuth().currentUser?.getIdToken(true);
    const result: WithApiResult<AuthResult> = yield backendService.post<AuthResult>('/api/auth', {
      email,
      uid,
      token,
    });
    if (result.kind === 'ok') {
      yield put(
        userActions.fetchInformation({
          displayName,
          photoURL,
          ...result.data,
          uid,
          email,
        })
      );
      if (!_.find(result.data.units ?? [], ['id', result.data.activeUnit ?? ''])) {
        yield put(userActions.selectUnit(result.data.units?.[0]?.id ?? ''));
      }
      return;
    }
    notification.warning({
      message: t(translations.errors.signInFailed).toString(),
      description: formatError(result),
    });
    yield signOutFirebase(getAuth());
  }
  yield put(userActions.fetchInformation(null));
}

function* signOut() {
  yield signOutFirebase(getAuth());
  backendService.post('/api/signedOut');
  window.location.reload();
}

export default function* saga() {
  yield all([
    takeLatest(userActions.signedIn.type, signedIn),
    takeLatest(userActions.signOut.type, signOut),
  ]);
}
