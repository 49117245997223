import { Divider, Space, Typography } from 'antd';

import useStyles from './styles';

type Props = {
  header: React.ReactNode;
  actions?: React.ReactNode;
  content: React.ReactNode;
};
const PageContent = ({ header, actions, content }: Props) => {
  const S = useStyles();
  return (
    <Space className={S.container} direction="vertical">
      <div className={S.header}>
        <Typography.Title level={5}>{header}</Typography.Title>
        {actions}
      </div>
      <Divider />
      {content}
    </Space>
  );
};

export default PageContent;
