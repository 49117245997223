import { Button, Popconfirm, theme } from 'antd';
import { BiCaretRight } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import { MdEdit, MdShare } from 'react-icons/md';

type Props = {
  confirmation: string | null;
  onConfirm: () => void;
  onClick: () => void;
};

export const RemoveButton = ({ confirmation, onConfirm, onClick }: Partial<Props>) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      danger
      shape="circle"
      type="link"
      size="small"
      onClick={onClick}
      icon={<FaTrash color={token.colorError} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};

export const ShareButton = ({ confirmation, onConfirm, onClick }: Partial<Props>) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      shape="circle"
      type="link"
      size="small"
      onClick={onClick}
      icon={<MdShare color={token.colorPrimary} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};

export const EditButton = ({ confirmation, onConfirm, onClick }: Partial<Props>) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      shape="circle"
      type="link"
      size="small"
      onClick={onClick}
      icon={<MdEdit color={token.colorPrimary} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};

export const SelectButton = ({ confirmation, onConfirm, onClick }: Partial<Props>) => {
  const { token } = theme.useToken();
  const button = (
    <Button
      shape="circle"
      type="link"
      size="small"
      onClick={onClick}
      icon={<BiCaretRight size={20} color={token.colorPrimary} />}
    />
  );
  if (confirmation) {
    return (
      <Popconfirm title={confirmation} onConfirm={onConfirm}>
        {button}
      </Popconfirm>
    );
  }
  return button;
};
