import { initializeApp } from 'firebase/app';

initializeApp(
  ['development', 'qa'].includes(process.env.REACT_APP_STAGE ?? '')
    ? {
        apiKey: 'AIzaSyDOQ4LoxE9kRvDIGj7INm-_vwz6Dts4l3s',
        authDomain: 'xbot-accounts-dev.firebaseapp.com',
        databaseURL: 'https://xbot-accounts-dev.firebaseio.com',
        projectId: 'xbot-accounts-dev',
        storageBucket: 'xbot-accounts-dev.appspot.com',
        messagingSenderId: '562168795958',
        appId: '1:562168795958:web:91350064cbe23c5d9a9997',
      }
    : {
        apiKey: 'AIzaSyAIwY1lw-_1vWGAzY6W8Pceds-hxdmmpsg',
        authDomain: 'xbot-accounts.firebaseapp.com',
        databaseURL: 'https://xbot-accounts.firebaseio.com',
        projectId: 'xbot-accounts',
        storageBucket: 'xbot-accounts.appspot.com',
        messagingSenderId: '494095097208',
        appId: '1:494095097208:web:a3e79a3bb261d44307fc8d',
      }
);

export const consoleApp = initializeApp(
  {
    apiKey: 'AIzaSyAXU3T67kd451yTa0NuhqNt6LPTVIfsWGg',
    authDomain: 'console-release.firebaseapp.com',
    projectId: 'console-release',
    storageBucket: 'console-release.appspot.com',
    messagingSenderId: '899518756311',
    appId: '1:899518756311:web:e3f11f2d7316186baa8792',
    measurementId: 'G-C6V35MKBFV',
  },
  'console'
);
