import { Layout, notification } from 'antd';
import AuthorizedRoute from 'AuthorizedRoute';
import { Waiting } from 'components';
import { BIG_SCREEN, NORMAL_SCREEN, SMALL_SCREEN, TINY_SCREEN } from 'configs';
import { Footer, Paths } from 'features/layout/components';
import { useUserSlice } from 'features/user/store';
import { selectUserEmail, selectUserHandling } from 'features/user/store/selectors';
import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  signInWithEmailLink,
} from 'firebase/auth';
import { lazy, Suspense, useEffect, useMemo } from 'react';
import { ThemeProvider } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

const Header = lazy(() => import('features/layout/components/Header'));
const Sider = lazy(() => import('features/layout/components/Sider'));
const NotFoundPage = lazy(() => import('pages/NotFound'));
const LoginPage = lazy(() => import('pages/Login'));
const HomePage = lazy(() => import('pages/Home'));
const InboundAPIs = lazy(() => import('pages/InboundAPIs'));
const OutboundAPIs = lazy(() => import('pages/OutboundAPIs'));

const AppLayout = ({ signedIn, theme }: { signedIn: boolean; theme: ResponsiveTheme }) => (
  <Suspense fallback={<Waiting />}>
    <ThemeProvider theme={theme}>
      {signedIn ? (
        <Layout>
          <Header />
          <Layout.Content>
            <Layout>
              <Sider />
              <Layout.Content>
                <Layout>
                  <Paths />
                  <Layout.Content
                    style={{
                      overflow: 'auto',
                      height: window.innerHeight - 170,
                      background: 'rgb(24, 27, 31)',
                      margin: 10,
                      padding: '10px 20px',
                      borderRadius: 10,
                    }}
                  >
                    <Outlet />
                  </Layout.Content>
                </Layout>
              </Layout.Content>
            </Layout>
          </Layout.Content>
        </Layout>
      ) : (
        <Outlet />
      )}
      {signedIn && <Footer />}
    </ThemeProvider>
  </Suspense>
);

function App() {
  const isTinyScreen = useMediaQuery(TINY_SCREEN);
  const isNormalScreen = useMediaQuery(SMALL_SCREEN);
  const isSmallScreen = useMediaQuery(NORMAL_SCREEN);
  const isBigScreen = useMediaQuery(BIG_SCREEN);
  const dispatch = useDispatch();
  const userEmail = useSelector(selectUserEmail);
  const userHandling = useSelector(selectUserHandling);
  const { actions } = useUserSlice();

  const theme: ResponsiveTheme = useMemo(
    () => ({
      isTinyScreen,
      isNormalScreen,
      isSmallScreen,
      isBigScreen,
    }),
    [isTinyScreen, isNormalScreen, isSmallScreen, isBigScreen]
  );

  useEffect(() => {
    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        notification.warning({
          message: 'Link không hợp lệ',
          description: 'Link đã được mở trên một thiết bị khác. Xin vui lòng đăng nhập lại',
        });
      } else {
        signInWithEmailLink(getAuth(), email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((err) => {
            notification.error({
              message: 'Lỗi',
              description: err.message,
            });
          });
      }
    }
    return onAuthStateChanged(getAuth(), (user) => {
      dispatch(
        actions.signedIn(
          user?.uid && user?.email
            ? {
                uid: user.uid,
                email: user.email,
                displayName: user?.displayName ?? '',
                photoURL: user?.photoURL ?? '',
              }
            : null
        )
      );
    });
  }, [actions, dispatch]);

  const signedIn = useMemo(() => Boolean(userEmail), [userEmail]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppLayout signedIn={signedIn} theme={theme} />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<AuthorizedRoute />}>
          <Route path="" element={<HomePage />} />
        </Route>
        <Route path="/apis" element={<AuthorizedRoute />}>
          <Route path="inbound" element={<InboundAPIs />} />
          <Route path="outbound" element={<OutboundAPIs />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    )
  );
  return (
    <>
      {userHandling ? <Waiting /> : null}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
