import { theme as antTheme, type ThemeConfig } from 'antd';
import { t } from 'i18next';
import { translations } from 'locales/translations';

export const form = {
  validateMessages: {
    default: t(translations.errors.validateMessages.default) ?? 'default',
    required: t(translations.errors.validateMessages.required) ?? 'required',
    date: {
      format: t(translations.errors.validateMessages.date.format) ?? 'wrong date format',
      parse: t(translations.errors.validateMessages.date.parse) ?? 'wrong date format',
      invalid: t(translations.errors.validateMessages.date.invalid) ?? 'invalid date',
    },
    string: {
      len: t(translations.errors.validateMessages.string.len) ?? 'invalid length',
      min: t(translations.errors.validateMessages.string.min) ?? 'invalid length',
      max: t(translations.errors.validateMessages.string.max) ?? 'invalid length',
      range: t(translations.errors.validateMessages.string.range) ?? 'invalid length',
    },
    number: {
      len: t(translations.errors.validateMessages.number.len) ?? 'invalid number',
      min: t(translations.errors.validateMessages.number.min) ?? 'invalid number',
      max: t(translations.errors.validateMessages.number.max) ?? 'invalid number',
      range: t(translations.errors.validateMessages.number.range) ?? 'invalid number',
    },
    array: {
      len: t(translations.errors.validateMessages.array.len) ?? 'invalid array',
      min: t(translations.errors.validateMessages.array.min) ?? 'invalid array',
      max: t(translations.errors.validateMessages.array.max) ?? 'invalid array',
      range: t(translations.errors.validateMessages.array.range) ?? 'invalid array',
    },
    pattern: {
      mismatch: t(translations.errors.validateMessages.pattern.mismatch) ?? 'wrong syntax',
    },
  },
};

export const theme: ThemeConfig = {
  algorithm: antTheme.darkAlgorithm,
  token: {
    colorBgLayout: 'transparent',
  },
  components: {
    Table: {
      stickyScrollBarBg: 'black',
    },
  },
};
